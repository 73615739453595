<template>
  <main v-if="entity">
    <div class="product-pic">
      <img :src="entity.pic.indexOf('https://') != -1?entity.pic:'https://www.bzwz.com'+entity.pic" alt="" />
    </div>
    <div class="product-info">
      <div class="product-name">{{ entity.title }}</div>
      <div class="operation">{{ entity.needCredit }} 积分</div>
    </div>
    <div class="product-detail">
      <div class="title">商品详情</div>
      <div class="content">
        <table border="1" cellspacing="0" cellpadding="10px">
          <thead>
            <tr>
              <th colspan="2">基本信息</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="name">产品名称</td>
              <td>{{ entity.title }}</td>
            </tr>
            <tr>
              <td class="name">产品介绍</td>
              <td>{{ entity.description }}</td>
            </tr>

            <tr>
              <td class="name">市场价</td>
              <td>{{ entity.marketPrice }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
<!--    <div class="lip_addres">-->
<!--      <img src="@/assets/img/mine/icon77.png" alt=""  style="float:left;width: 15px;"/>-->
<!--      <div>-->
<!--        <span class="contant" style=" margin-right: 20px;">-->
<!--          肖航-->
<!--        </span>-->
<!--        <span class="contant">17629999222</span>-->
<!--        <div style="margin-left: 0.48rem;">-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="contant">-->
<!--          地址：河南 信阳 商城县 奥科-->
<!--      </div>-->
<!--    </div>-->
<!--    <div style="margin-top: 12px;">-->
<!--      <div class="dui_info">商品信息</div>-->
<!--      <div class="product-pic">-->
<!--        <img :src="entity.pic.indexOf('https://') != -1?entity.pic:'https://www.bzwz.com'+entity.pic" alt="" />-->
<!--      </div>-->
<!--      <div>-->
<!--        <span class="sinfo">产品名称：{{ entity.subtitle }}</span>-->
<!--        <span class="sinfo">兑换积分：{{ entity.needCredit }}</span>-->
<!--      </div>-->
<!--    </div>-->
  </main>
  <loading :options="loadOption" v-else></loading>
  <div class="footer">
    <div class="button-wrapper">
      <ui-button type="submit" @handleclick="onChange"> 兑换</ui-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";
import { useRoute, useRouter } from "vue-router";
import Loading from "@/components/UI/Loading";
import UiButton from "@/components/UI/Button";
import qs from "qs";
import Modal from "@/components/UI/Modal";

export default defineComponent({
  name: "Exchange",
  components: {
    Loading,
    UiButton,
  },
  setup() {
    const route = useRoute();
    const id = route.query.id;
    const entity = ref(null);
    axios
      .get(`/M/User/GiftDetail/${id}`, {
        params: {
          validate: true,
        },
      })
      .then((res) => {
        if (res.data.success) {
          entity.value = res.data.obj.entity;
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };
    const router = useRouter();
    let pending = false;
    
    function onChange() {
      Modal({
        title: "温馨提示",
        content: "确认兑换该商品？",
        onConfirm: () => {
          if (!pending) {
            pending = true;
            const data = qs.stringify({
              id,
            });
            axios
              .post(`/M/Server/DoExchangeProduct`, data, {
                params: {
                  validate: true,
                },
              })
              .then((res) => {
                if (res.data.success) {
                  Toast({
                    type: "success",
                    title: res.data.msg,
                    duration: 2000,
                    onClose:()=>{
                       router.back();
                    }
                  });
                } else {
                  Toast({
                    type: "error",
                    title: res.data.msg,
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
          pending = false;
        },
        onClose: () => {
          pending = false;
        },
      });
    }
    return {
      entity,
      loadOption,
      onChange,
    };
  },
});
</script>

<style lang="scss" scoped>
main {
  padding-bottom: 69px;
}

.lip_addres{
  border-bottom: 0.2rem solid #f4f4f4;
  margin-top: 0.48rem;
  padding-bottom: 0.48rem;
  padding-left: 0.48rem;
}
.contant{
  color: #444;
  font-size: 0.4rem;
}

.dui_info{
  font-size: 16px;
  color: #333;
  text-indent: 14px;
  font-weight: bold;
}

/*.product-pic{*/
/*  float:left;*/
/*  margin:12px 12px 0 12px;*/
/*}*/

.product-pic img {
  width: 10rem;
  height: 10rem;
  display: block;
}

.sinfo{
  color: #444;
  font-size: 0.4rem;
  display: inline-block;
  margin-top: 0.6rem;
}
.product-info {
  position: relative;
  padding: 10px;
  padding-top: 21.5px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 6px solid #f4f4f4;
}

.product-name {
  width: 255px;
  color: #444444;
  font-size: 16px;
  position: relative;
  font-weight: bold;
}

.operation {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #cc3333;
  font-size: 15px;
  font-weight: 700;
}
.operation a {
  color: #cc3333;
  font-size: 15px;
  font-weight: 700;
}

.product-detail .title {
  padding-left: 20px;
  font-size: 15px;
  color: #444;
  position: relative;
  width: 375px;
  box-sizing: border-box;
  height: 44px;
  line-height: 44px;
  border-bottom: 1px solid #f4f4f4;
}
.product-detail .title::after {
  content: "";
  position: absolute;
  width: 2px;
  left: 10px;
  top: 50%;
  height: 13px;
  background-color: #df0024;
  margin-top: -6.5px;
}

table {
  width: 355px;
  border: none;
  margin: 0 auto;
  margin-top: 20px;
  border-collapse: collapse;
  border-color: #ccc;
}
thead {
  width: 355px;
  background-color: #df0024;
  border: 1px solid #df0024;
}
th {
  color: #fff;
  text-align: left;
  height: 30px;
  box-sizing: border-box;
  padding: 0 9px;
  line-height: 30px;
  font-size: 14px;
}
td {
  font-size: 14px;
  color: #666666;
  padding: 10px;
  word-break: break-word;
}

.name {
  width: 117px;
  box-sizing: border-box;
  border-right-color: #e5e5e5;
  background-color: #f2f2f2;
  line-height: 25px;
}

.other-view {
  margin-top: 20px;
  border-top: 1px solid #eee;
}
.other-view .title {
  text-align: justify;
  font-size: 16px;
  color: #666666;
  line-height: 25px;
  margin: 20px 10px;
  font-weight: bold;
}

.other-view .content a {
  text-align: justify;
  font-size: 16px;
  line-height: 25px;
  display: block;
  color: #666666 !important;
  margin: 12.5px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0%;

  right: 0;
  width: 100%;
  border-top: 1px solid #f4f4f4;

  background-color: #fff;
  z-index: 100;
  .button-wrapper {
    width: 345px;
    margin: 0 auto;
  }
}
</style>
