
import { defineComponent, ref } from "vue";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";
import { useRoute, useRouter } from "vue-router";
import Loading from "@/components/UI/Loading";
import UiButton from "@/components/UI/Button";
import qs from "qs";
import Modal from "@/components/UI/Modal";

export default defineComponent({
  name: "Exchange",
  components: {
    Loading,
    UiButton,
  },
  setup() {
    const route = useRoute();
    const id = route.query.id;
    const entity = ref(null);
    axios
      .get(`/M/User/GiftDetail/${id}`, {
        params: {
          validate: true,
        },
      })
      .then((res) => {
        if (res.data.success) {
          entity.value = res.data.obj.entity;
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    const loadOption = {
      text: "加载中...",
      color: "#df0024",
      textColor: "#df0024",
    };
    const router = useRouter();
    let pending = false;
    
    function onChange() {
      Modal({
        title: "温馨提示",
        content: "确认兑换该商品？",
        onConfirm: () => {
          if (!pending) {
            pending = true;
            const data = qs.stringify({
              id,
            });
            axios
              .post(`/M/Server/DoExchangeProduct`, data, {
                params: {
                  validate: true,
                },
              })
              .then((res) => {
                if (res.data.success) {
                  Toast({
                    type: "success",
                    title: res.data.msg,
                    duration: 2000,
                    onClose:()=>{
                       router.back();
                    }
                  });
                } else {
                  Toast({
                    type: "error",
                    title: res.data.msg,
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
          pending = false;
        },
        onClose: () => {
          pending = false;
        },
      });
    }
    return {
      entity,
      loadOption,
      onChange,
    };
  },
});
